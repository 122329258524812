<template>
  <div class="licences" id="licences">
    <div class="container ">
      <h2>Licences</h2>
      <b-row class="mx-sm-n5" align-v="center" align-h="center">
        <b-col
          lg="3"
          md="6"
          sm="6"
          xs="12"
          v-for="(licence, index) in licences"
          :key="index"
        >
          <vs-card>
            <template #title>
              <h6>{{ licence.name }}</h6>
              <h3 class="price">${{ licence.price }}</h3>
              <small>*{{ licence.promoText }}</small>
            </template>
            <template #text>
              <ul>
                <li
                  v-for="(condition, index) in licence.conditions"
                  :key="index"
                >
                  {{ condition }}
                </li>
              </ul>
            </template>
          </vs-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "LicencesComponent",
  components: {},
  data: () => ({
    licences: [
      {
        name: "Wav Lease",
        price: 29.95,
        promoText: "Buy 2, get 1 free (select 3 beats)",
        conditions: [
          "Receive untagged (Wav file)",
          "Promotional use",
          "Sales are not allowed",
          "YouTube monetization (up to 30,000 views)",
          "10.000 audio streams (Spotify, Apple Music, etc)",
          "It is not allowed to register the beat",
          "Fabricio Loupias maintains the rights",
          "Credits: Prod. Fabricio Loupias"
        ]
      },
      {
        name: "Wav Premium",
        price: 39.95,
        promoText: "Buy 2, get 1 free (select 3 beats)",
        conditions: [
          "Receive untagged (Wav file)",
          "Commercial use",
          "Sell up to 6.000",
          "YouTube monetization (up to 80,000 views)",
          "30.000 audio streams (Spotify, Apple Music, etc)",
          "It is not allowed to register the beat",
          "Fabricio Loupias maintains the rights",
          "Credits: Prod. Fabricio Loupias"
        ]
      },
      {
        name: "Tracks out",
        price: 69.95,
        promoText: "Buy 2, get 1 free (select 3 beats)",
        conditions: [
          "Receive untagged (Wav file) and Track Outs stem files",
          "Commercial use",
          "Sell up to 10.000",
          "YouTube monetization (up to 150,000 views)",
          "80.000 audio streams (Spotify, Apple Music, etc)",
          "It is not allowed to register the beat",
          "Fabricio Loupias maintains the rights",
          "Credits: Prod. Fabricio Loupias"
        ]
      },
      {
        name: "Unlimited",
        price: 149.95,
        promoText: "Buy 2, get 1 free (select 3 beats)",
        conditions: [
          "Receive untagged (Wav file) and Track Outs stem files",
          "Commercial use",
          "Unlimited",
          "YouTube monetization (unlimited views)",
          "Unlimited audio streams (Spotify, Apple Music, etc)",
          "It is not allowed to register the beat",
          "Fabricio Loupias maintains the rights",
          "Credits: Prod. Fabricio Loupias"
        ]
      }
    ]
  })
};
</script>

<style lang="scss">
.licences {
  margin: 100px 0;
  h2 {
    margin-bottom: 50px;
  }
  .vs-card {
    max-width: 97%;
    margin: auto;
    margin-bottom: 20px;
    min-height: 490px;
  }
  .price {
    margin: 20px 0;
    font-size: 40px;
  }
  .vs-card__title {
    margin-bottom: 36px;
    padding-top: 20px;
  }
  .vs-card__text {
    ul {
      padding-inline-start: 30px;
      li {
        text-align: left;
        margin-bottom: 16px;
      }
    }
  }
}
</style>
