<template>
  <div class="home">
    <!-- <cover-component /> -->
    <beat-store-component />
    <licences-component />
    <footer-component />
  </div>
</template>

<script>
import BeatStoreComponent from "../components/BeatStoreComponent.vue";
import FooterComponent from "../components/FooterComponent.vue";
import LicencesComponent from "../components/LicencesComponent.vue";

export default {
  name: "Home",
  components: {
    BeatStoreComponent,
    LicencesComponent,
    FooterComponent
  }
};
</script>
