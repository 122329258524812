<template>
  <div class="beat-store" id="store">
    <b-container>
      <b-row class="mx-sm-n5" style="margin-bottom: 50px">
        <b-col cols="12">
          <iframe
            title="Player"
            src="https://player.beatstars.com/?storeId=116303"
            width="100%"
            height="700"
            style="border: none"
          >
            -- none --
          </iframe>
        </b-col>
      </b-row>
      <b-row class="steps mx-sm-n5">
        <b-col md="4" xs="12">
          <vs-card>
            <template #title>
              <h3>Select your beats</h3>
            </template>
            <template #img>
              <img
                class="icon"
                src="@/assets/icons/round-add-button.svg"
                alt=""
              />
            </template>
            <template #text>
              <p>
                Browse through the store and select the beat(s) you’d like.
              </p>
            </template>
          </vs-card>
        </b-col>
        <b-col md="4" xs="12">
          <vs-card>
            <template #title>
              <h3>Securely checkout</h3>
            </template>
            <template #img>
              <img class="icon" src="@/assets/icons/checkout.svg" alt="" />
            </template>
            <template #text>
              <p>
                Proceed to checkout and make payment via PayPal or Card
              </p>
            </template>
          </vs-card>
        </b-col>
        <b-col md="4" xs="12">
          <vs-card>
            <template #title>
              <h3>Download your beats</h3>
            </template>
            <template #img>
              <img
                class="icon"
                src="@/assets/icons/cloud-computing.svg"
                alt=""
              />
            </template>
            <template #text>
              <p>
                Receive your beat(s) straight to your inbox and download.
              </p>
            </template>
          </vs-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "BeatStoreComponent",
  components: {},
  data: () => ({})
};
</script>

<style lang="scss">
.beat-store {
  margin: 150px 0;
  h2 {
    margin-bottom: 50px;
  }
}

.steps .vs-card__img {
  width: 76px;
  margin: auto;
  padding: 10px;
}
.steps .vs-card {
  max-width: 97%;
  margin: auto;
  margin-bottom: 20px;
}
</style>
