<template>
  <div class="footer my-5" id="footer">
    <vs-row justify="center">
      <vs-col vs-justify="center" vs-align="center" w="8" sm="12">
        <vs-row justify="space-between">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" sm="12">
            <p>All rights reserved © {{ year }} Falou Music</p>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  components: {},
  data: () => ({
    year: new Date().getFullYear()
  })
};
</script>

<style lang="scss">
.footer {
  padding: 50px 0;
  p {
    color: rgba(255, 255, 255, 0.3);
    text-align: center;
  }
}
</style>
